import React from 'react'
import {Link} from 'react-router-dom'

const Notfoundpage = () => {
  return (
    <div>
    No page. Go <Link to="/">Home</Link>.
    </div>
  )
}

export default Notfoundpage