export const joblist = [
  /* {
    id: 2,
    img: require('./img/body-1.jpg'),
    title: '?',
    services: '-',
  }, */
  {
    id: 3,
    img: require('./img/bushing-1.jpg'),
    title: 'Втулка',
    services: '-',
  },
  /* {
    id: 33,
    img: require('./img/bushing-2.jpg'),
    title: 'Втулка',
    services: '-',
  }, */
  {
    id: 4,
    img: require('./img/coupling.jpg'),
    title: 'Муфта',
    services: '-',
  },
  /* {
    id: 5,
    img: require('./img/cup-1.jpg'),
    title: 'Стаканы',
    services: '-',
  }, */
  {
    id: 6,
    img: require('./img/fitting-1.jpg'),
    title: 'Штуцер',
    services: '-',
  },
  {
    id: 7,
    img: require('./img/flange-1.jpg'),
    title: 'Фланец',
    services: '-',
  },
  /*  {
    id: 8,
    img: require('./img/flange-2.jpg'),
    title: 'Фланец',
    services: '-',
  }, */
  {
    id: 9,
    img: require('./img/hairpins-1.jpg'),
    title: 'Шпилька',
    services: '-',
  },
  /* {
    id: 10,
    img: require('./img/hairpins-2.jpg'),
    title: 'Шпилька',
    services: '-',
  }, */
  {
    id: 11,
    img: require('./img/ring-1.jpg'),
    title: 'Кольца',
    services: '-',
  },
  /*  {
    id: 12,
    img: require('./img/shaft-1.jpg'),
    title: 'Вал',
    services: '-',
  }, */
  /* {
    id: 13,
    img: require('./img/shaft-2.jpg'),
    title: 'Вал',
    services: '-',
  },
  {
    id: 14,
    img: require('./img/wheel.jpg'),
    title: 'Колесо',
    services: '-',
  },
  {
    id: 15,
    img: require('./img/wheel-2.jpg'),
    title: 'Колесо',
    services: '-',
  },
  {
    id: 16,
    img: require('./img/bushing-3.jpg'),
    title: 'Втулка',
    services: '-',
  },
  {
    id: 17,
    img: require('./img/bushing-4.jpg'),
    title: 'Втулка',
    services: '-',
  },
  {
    id: 18,
    img: require('./img/bushing-5.jpg'),
    title: 'Втулка',
    services: '-',
  }, */
  /* {
    id: 19,
    img: require('./img/shaft-3.jpg'),
    title: 'Вал',
    services: '-',
  }, */
];
