import React from "react";
import Requisites from "../components/Requisites";

const Aboutpage = () => {
  return (
    <div>
      <div>Раздел находится в процессе оформления, а пока вы можете скачать наши реквизиты</div>
      <Requisites />
    </div>
  );
};

export default Aboutpage;
